import React, { FC } from 'react';
import classNames from 'classnames';

import IconCustom from 'gatsby-theme-dettol/src/components/common/IconCustom';
import HeaderNavDropdownMenu from 'gatsby-theme-dettol/src/components/Header/HeaderNavDropdownMenu';
import isTouchDevice from 'gatsby-theme-dettol/src/utils/isTouchDevice';

import { IHeaderNavDropdownItem } from 'gatsby-theme-dettol/src/components/Header/HeaderNavDropdownItem/models';

import 'gatsby-theme-dettol/src/components/Header/HeaderNavDropdownItem/HeaderNavDropdownItem.scss';

const HeaderNavDropdownItem: FC<IHeaderNavDropdownItem> = ({
  showMenu,
  isMenuShown,
  navItem,
  currentPage,
}) => {
  const dropDownClasses = classNames('dt-header__dropdown', {
    'dt-header__dropdown--hover': isMenuShown,
  });

  return (
    <div
      className={dropDownClasses}
      data-testid="dt-header"
      onMouseOver={() => !isTouchDevice() && showMenu(true)}
      onFocus={() => {}}
      onMouseOut={() => !isTouchDevice() && showMenu(false)}
      onBlur={() => {}}
    >
      {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
      <a
        title={navItem.label}
        href="/"
        className={classNames('nav-link', {
          active: currentPage === 'product-page',
        })}
        role="button"
        onClick={(event) => {
          event.preventDefault();
          showMenu(!isMenuShown);
        }}
      >
        {navItem.label}

        <span className="dt-header__dropdown-caret">
          <IconCustom icon="next_arrow" />
        </span>
      </a>

      {navItem.panels && <HeaderNavDropdownMenu panels={navItem.panels} />}
    </div>
  );
};

export default HeaderNavDropdownItem;
